import React, { useState, useEffect, createRef } from "react";
import { storyData } from "../data/data";
import { motion } from "framer-motion";
import {
  Image,
  Skeleton,
  Divider,
  Row,
  Col,
  Button,
  Modal,
  Checkbox,
  Form,
  Radio,
} from "antd";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaHistory,
} from "react-icons/fa";
import { FaRegCirclePlay } from "react-icons/fa6";
import ReactPlayer from "react-player";

function Story() {
  const [loading, setLoading] = useState(false);
  const [storyLoading, setStoryLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAutoPlay, setOpenAutoPlay] = useState(false);
  const [autoPlay, setAutoPlay] = useState({
    autoplay: false,
    shuffle: false,
    seconds: 20,
  });
  const form = createRef();

  const [index, setIndex] = useState(1);

  const getStoryData = (index) => {
    const data = storyData.find((story) => story.index === index);
    if (!data) {
      console.error(`No Story data found with index: ${index}`);
      return null;
    }
    return data;
  };

  const currentStoryData = getStoryData(index);

  const showLoading = () => {
    setOpen(true);

    // Simple loading mock. You should add cleanup logic in real world.
    setTimeout(() => {
      setStoryLoading(false);
    }, 2000);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex < storyData.length ? prevIndex + 1 : 1));
  };

  const handlePrevious = () => {
    setIndex((prevIndex) => (prevIndex > 1 ? prevIndex - 1 : prevIndex));
  };

  const changeStoryIndex = (index) => {
    setOpen(false);
    setIndex(index);
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 500);
    return () => clearTimeout(timer);
  }, [index]);

  useEffect(() => {
    if (autoPlay.autoplay) {
      console.log(autoPlay);

      const seconds = parseInt(autoPlay.seconds * 1000, 10);

      const shuffle = autoPlay.shuffle ? true : false;

      const interval = setTimeout(() => {
        if (shuffle) {
          const random = Math.floor(Math.random() * storyData.length);
          console.log(random);
          setIndex(random);
        } else {
          handleNext();
        }
      }, seconds);

      // Cleanup the interval on component unmount or when autoPlay changes
      return () => clearTimeout(interval);
    }
  }, [autoPlay, handleNext]);

  if (!currentStoryData) {
    return <div>Error loading story.</div>;
  }

  const _title = currentStoryData.title.split(" ");
  const _text = currentStoryData.text.split(" ");

  const animateText = (text) => {
    return text.map((el, i) => (
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.25,
          delay: i / 10,
        }}
        key={i}
      >
        {el}{" "}
      </motion.span>
    ));
  };

  const autoPlaySave = () => {
    const autoPlayConfig = form.current.getFieldsValue();

    setOpenAutoPlay(false);
    setAutoPlay({
      autoplay: autoPlayConfig.autoplay,
      shuffle: autoPlayConfig.shuffle,
      seconds: autoPlay.seconds,
    });
  };

  const timeOptions = [15, 30, 45, 60];

  const changeAutoplayDelay = ({ target: { value } }) => {
    console.log("radio2 checked", value);
    setAutoPlay({ seconds: value });
  };

  return (
    <>
      <div>
        <Modal
          width={1000}
          title={<p className="text-center text-3xl">Select your story</p>}
          loading={storyLoading}
          open={open}
          onCancel={() => setOpen(false)}
          footer={false}
        >
          <Row className="gap-2 mt-6" justify="center">
            {storyData.map((item, index) => (
              <>
                <Col key={index}>
                  <Button onClick={() => changeStoryIndex(item.index)}>
                    {item.title}
                  </Button>
                </Col>
              </>
            ))}
          </Row>
        </Modal>
      </div>
      <div>
        <Modal
          width={500}
          title={<p className="text-center text-3xl">Auto Play</p>}
          open={openAutoPlay}
          onCancel={() => setOpenAutoPlay(false)}
          onOk={autoPlaySave}
        >
          <Row className="mt-6" justify="center">
            <div className="w-[600px] text-center">
              <Form ref={form} onFinish={autoPlaySave}>
                <Form.Item name="autoplay" valuePropName="checked">
                  <Checkbox title="Enable Autoplay" value={"false"}>
                    Enable Autoplay
                  </Checkbox>
                </Form.Item>
                <Form.Item name="shuffle" valuePropName="checked">
                  <Checkbox title="Enable Autoplay" value={"false"}>
                    Enable shuffle
                  </Checkbox>
                </Form.Item>

                <p className="mb-1">Autoplay seconds</p>
                <Form.Item>
                  <Radio.Group
                    options={timeOptions}
                    onChange={changeAutoplayDelay}
                    value={autoPlay.seconds}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Form>
            </div>
          </Row>
        </Modal>
      </div>
      <div className="h-full w-full">
        {autoPlay.autoplay && (
          <div className="mx-auto text-center mb-4">
            <Button
              className="font-bold  text-lg  w-48 h-16 bg-[#ffffff] text-[#000000] justify-center"
              onClick={() => setAutoPlay({ autoplay: false })}
            >
              Stop Autoplay
            </Button>
          </div>
        )}

        {loading && (
          <div className="p-4 border rounded-lg shadow-md w-[1500px] mx-auto bg-accent">
            <Skeleton title={true} loading={loading} paragraph={true} active />
          </div>
        )}
        {!loading && (
          <div className="flex justify-center mx-auto">
            <motion.div
              className="p-4 border rounded-lg shadow-md bg-accent w-[1500px]"
              initial={{
                opacity: 0,
                x: index % 2 === 0 ? 50 : -50,
              }}
              animate={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.5,
                },
              }}
            >
              <div className="flex-1 mb-4 text-balance">
                <div className="flex justify-between items-center">
                  {index > 1 && (
                    <motion.button>
                      <span
                        className="w-[200px] h-[50px] text-2xl text-gray-700"
                        onClick={handlePrevious}
                        disabled={index === 1}
                      >
                        <FaArrowCircleLeft
                          className="hover:scale-125 transition-transform"
                          size={42}
                        />
                      </span>
                    </motion.button>
                  )}

                  <h2 className="text-3xl font-bold text-center mx-auto">
                    {animateText(_title)}
                  </h2>

                  <motion.button>
                    <span
                      className="w-[200px] h-[50px] text-2xl text-gray-700"
                      onClick={handleNext}
                    >
                      <FaArrowCircleRight
                        className="hover:scale-125 transition-transform"
                        size={42}
                      />
                    </span>
                  </motion.button>
                </div>

                <Divider />
                <p className="mt-4 text-center">{animateText(_text)}</p>
              </div>

              <Divider />
              <div className="flex justify-center">
                <Row className="gap-8" justify="center">
                  <Image.PreviewGroup>
                    {currentStoryData.media.map((item, index) => (
                      <Col key={index}>
                        <motion.div
                          initial={{
                            opacity: 0,
                            x: index % 2 === 0 ? 50 : -50,
                          }}
                          animate={{
                            opacity: 1,
                            x: 0,
                            transition: {
                              duration: 0.5,
                            },
                          }}
                        >
                          {item.type === "image" ? (
                            <Image
                              className="rounded-lg sm:w-[200px]"
                              src={item.src}
                              loading="lazy"
                              height={400}
                              width={400}
                              onLoadStart={() => setLoading(true)}
                              onLoad={() => setLoading(false)}
                            />
                          ) : (
                            <ReactPlayer
                              className="rounded-lg"
                              url={item.src}
                              height={400}
                              width={item.width ? item.width : 220}
                              controls={true}
                              playIcon={true}
                              muted={true}
                              loop={true}
                              playing={true}
                            />
                          )}
                        </motion.div>
                      </Col>
                    ))}
                  </Image.PreviewGroup>
                </Row>
              </div>

              <div className="flex justify-between">
                <button
                  disabled
                  className="btn text-gray-600 flex justify-item-end"
                >
                  {currentStoryData.date}
                </button>

                <div className="flex ml-4">
                  <FaRegCirclePlay
                    type="primary"
                    onClick={() => setOpenAutoPlay(true)}
                    className="hover:scale-125 transition-transform cursor-pointer mr-4"
                    size={38}
                  />
                  <FaHistory
                    type="primary"
                    onClick={showLoading}
                    className="hover:scale-125 transition-transform cursor-pointer"
                    size={38}
                  />
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </div>
    </>
  );
}

export default Story;
