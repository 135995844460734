import { useState, useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import lauvSong from "../music/lauv.mp3";
import nadaSong from "../music/Nada.mp3";
import mikiSong from "../music/miki.mp3";
import tonightSong from "../music/tonight.mp3";
import gripinSong from "../music/gripin.mp3";
import guldunSong from "../music/guldun.mp3";
import lullaboy from "../music/lullaboy.mp3";

const MusicPlayer = () => {
  const [index, setIndex] = useState(1);

  const songs = [
    { index: 1, song: lauvSong },
    { index: 2, song: nadaSong },
    { index: 3, song: guldunSong },
    { index: 4, song: mikiSong },
    { index: 5, song: tonightSong },
    { index: 6, song: gripinSong },
    { index: 7, song: lullaboy },
  ];

  const currentSong = songs.find((song) => song.index === index).song;

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex < songs.length ? prevIndex + 1 : 1));
  };

  const handlePrevious = () => {
    setIndex((prevIndex) => (prevIndex > 1 ? prevIndex - 1 : songs.length));
  };

  return (
    <>
      <div className="w-[380px] mx-auto mt-2">
        <AudioPlayer
          className="rounded-lg"
          showJumpControls={false}
          showSkipControls={true}
          autoPlay={true}
          volume={0.2}
          loop={false}
          src={currentSong}
          onClickNext={handleNext}
          onClickPrevious={handlePrevious}
          onEnded={handleNext}
        />
      </div>
    </>
  );
};

export default MusicPlayer;
