import React from "react";
import Story from "./components/Story";
import MusicPlayer from "./components/MusicPlayer";
import { Analytics } from "@vercel/analytics/react";

const App = () => {
  return (
    <>
      <Analytics />
      <MusicPlayer />
      <div className="flex mx-auto mt-4">
        <Story />
      </div>
    </>
  );
};

export default App;
